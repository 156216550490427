import * as React from 'react'
import { useEffect, useState } from 'react'
import {
	Button,
	Flex,
	Tab,
	TabList,
	Tabs,
	Text,
	useToast
} from '@chakra-ui/react'
import FlipMove from 'react-flip-move'
import { StaticImage } from 'gatsby-plugin-image'

import LeaderboardItem from '../components/leaderboardItem'
import LeaderboardSummary from '../components/leaderboardSummary'
import {
	useInfiniteQuery,
	useQuery,
	useQueryClient,
	useMutation
} from '@tanstack/react-query'
import { getPublic, getSummary } from '../services/leaderboards'
import { navigate } from 'gatsby'
import io from 'socket.io-client'
import ClosePLSVG from '../icons/close-pl.svg'
import LeaderboardTabItem from '../components/leaderboardTabItem'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import Spinner from '../components/spinner'
import {
	calculateHeightValue,
	calculateWidthValue
} from '../helpers/dimensionTransformer'
import { errorToastOptions } from '../helpers'
import { getPublicTeamCount } from '../services/team'
import { checkCustomerStatus } from '../services/credits'
import Drawer from '../components/drawer'
import { updateGroupMemberByRfid } from '../services/groupMember'
import Modal from '../components/modal'

const socket = io.connect(process.env.REACT_APP_WEBSOCKET_URL)

const PublicLeaderboardPage = ({ games, rfid, setRFID }) => {
	const queryClient = useQueryClient()
	const [game, selectGame] = useState('0')
	const [tab, selectTab] = useState(0)
	const [profile, setProfile] = useState(null)
	const [groupMember, setGroupMember] = useState(null)
	const [group, setGroup] = useState(null)
	const [team, setTeam] = useState(null)
	const [data, setData] = useState([])
	const toast = useToast()
	const [openModal, setOpenModal] = useState(false)

	/**
	 * QUERIES
	 */

	const groupTeamsQuery = useQuery(
		['groupTeamsCount', team?.groupId],
		getPublicTeamCount,
		{ enabled: !!team?.groupId }
	)

	const customerStatusQuery = useQuery(
		['customerStatus', rfid, group?.reservationId?.toString()],
		checkCustomerStatus,
		{ enabled: !!rfid && !!group?.reservationId }
	)

	const leaderboardsQuery = useInfiniteQuery(
		[
			'leaderboards-interactive',
			30,
			tab,
			game,
			'group',
			team?.groupId,
			rfid
		],
		getPublic,
		{
			getNextPageParam: (lastPage) => {
				const { page, maxPage } = lastPage?.pagination || {}
				return page < maxPage ? page + 1 : null
			},
			onError: () => {
				toast({
					description:
						'Unable to find your group, please contact one of the team.',
					...errorToastOptions,
					position: 'bottom',
					onCloseComplete: () => {
						handleClose()
					}
				})
			}
		}
	)

	const {
		isLoading: isLeaderboardSummaryLoading,
		isError: isLeaderboardSummaryError,
		data: leaderboardSummaryData
	} = useQuery({
		queryKey: [
			'leaderboardSummaryPublic',
			game,
			team?.groupId,
			profile?.id
		],
		queryFn: () => getSummary(game, team?.groupId, profile?.id),
		// enabled: !!profile?.id && team?.groupId && profile?.emailId
		enabled: !!profile && !!profile?.id && !!team?.groupId
	})

	const [sentryRef] = useInfiniteScroll({
		loading: leaderboardsQuery.isLoading,
		hasNextPage: leaderboardsQuery.hasNextPage || false,
		onLoadMore: async () => {
			console.log('load more...')
			await leaderboardsQuery.fetchNextPage()
		},
		disabled: leaderboardsQuery.status === 'error',
		rootMargin: '0px 0px 1000px 0px'
	})

	/**
	 * CONSTANTS
	 */

	const creditsRemaining = groupMember?.creditsRemaining

	const userCanPlay = customerStatusQuery?.data?.canPlay

	const showBonusCreditsNotification =
		groupMember?.showBonusCreditsNotification

	const filteredGames = React.useMemo(() => {
		const groupMemberCredits = groupMember?.credits

		if (!groupMemberCredits) return []

		return games.filter((game) => {
			const gameCreditId = String(Number(game.node?.frontmatter?.id) + 1)

			return (
				game.node?.frontmatter?.id !== '0' &&
				groupMemberCredits[gameCreditId]
			)
		})
	}, [groupMember?.credits, games])

	const groupHasMultipleTeams =
		!groupTeamsQuery.isError &&
		!groupTeamsQuery.isLoading &&
		groupTeamsQuery.data.count > 1

	/**
	 * MUTATIONS
	 */

	const updateGroupMemberMutation = useMutation(updateGroupMemberByRfid, {
		onSuccess: (data) => {
			queryClient.invalidateQueries({
				queryKey: ['leaderboards-interactive']
			})
		},
		onError: (error) => {
			console.log(error)
		}
	})

	const handleCloseBonusCreditsNotification = () => {
		if (rfid) {
			updateGroupMemberMutation.mutate({
				rfid,
				body: { showBonusCreditsNotification: false }
			})
		}

		return
	}

	const handleClose = React.useCallback(() => {
		setRFID(null)
		navigate('/public-leaderboard')
	}, [setRFID])

	/**
	 * EFFECTS
	 */
	useEffect(() => {
		let screenTimeout

		const resetTimeout = () => {
			clearTimeout(screenTimeout)

			screenTimeout = setTimeout(() => {
				handleClose()
			}, 60000)
		}

		const handleClick = () => resetTimeout()

		resetTimeout()

		window.addEventListener('click', handleClick)

		return () => {
			window.removeEventListener('click', handleClick)
			clearTimeout(screenTimeout)
		}
	}, [handleClose])

	useEffect(() => {
		if (leaderboardsQuery.data?.pages) {
			const _data = []
			let _profile = null
			let _team = null
			let _groupMember = null
			let _group = null
			leaderboardsQuery.data.pages.forEach((page) => {
				_data.push(...page.leaderboard)
				_profile = page.profile || {}
				_team = page.userTeam || {}
				_groupMember = page.groupMember || {}
				_group = page.group || {}
			})
			setProfile(_profile)
			setGroupMember(_groupMember)
			setTeam(_team)
			setData(_data)
			setGroup(_group)
		} else {
			setData(null)
		}
	}, [leaderboardsQuery.data])

	useEffect(() => {
		socket.on('connect', () => {
			console.log('connected')
		})

		socket.on('leaderboards', (data) => {
			console.log('update leaderboards', data)
			queryClient.invalidateQueries(['leaderboards-interactive'])
		})

		socket.on('disconnect', () => {
			console.log('disconnected')
		})

		return () => {
			socket.off('connect')
			socket.off('disconnect')
			socket.off('leaderboards')
		}
	}, [queryClient])

	const venueTabIndex = groupHasMultipleTeams ? 2 : 1

	const handleTabClick = (idx) => {
		if (idx === venueTabIndex) {
			if (profile?.emailId) {
				selectTab(idx)
			} else {
				setOpenModal(true)
			}
		} else {
			selectTab(idx)
		}
	}

	if (!profile) {
		return (
			<Flex
				flex="1"
				direction="column"
				justifyContent="center"
				alignItems="center"
				position="relative"
			>
				<Button
					position="absolute"
					top={calculateHeightValue(120)}
					right={calculateWidthValue(120)}
					variant="icon"
					onClick={handleClose}
				>
					<ClosePLSVG
						width={calculateWidthValue(220)}
						height={calculateWidthValue(220)}
					/>
				</Button>
			</Flex>
		)
	}

	return (
		<Flex
			flex="1"
			direction="column"
			justifyContent="center"
			alignItems="center"
			position="relative"
			minH="100vh"
		>
			<Button
				position="absolute"
				top={calculateHeightValue(120)}
				right={calculateWidthValue(120)}
				variant="icon"
				onClick={handleClose}
			>
				<ClosePLSVG
					width={calculateWidthValue(220)}
					height={calculateWidthValue(220)}
				/>
			</Button>
			<Flex
				mt={calculateHeightValue(160)}
				minH={calculateHeightValue(234)}
				direction="column"
				justifyContent="center"
				alignItems="center"
			>
				<Text variant="namePL" color="white">
					{tab === 0 || tab === venueTabIndex
						? groupMember?.nickname || ' '
						: team?.name || groupMember?.nickname || ' '}
				</Text>
				<Text variant="versusPL" color="white" mt="-4px">
					vs {group?.name}
				</Text>
			</Flex>
			<Flex flex="1" direction="column" alignItems="center" w="100%">
				<Flex
					w="100%"
					mt="0"
					mx="0"
					mb={calculateHeightValue(50)}
					pl={calculateWidthValue(80)}
					pr={calculateWidthValue(20)}
					direction="row"
					overflowX="scroll"
					overflowY="hidden"
				>
					{games.map((item, index) => (
						<LeaderboardTabItem
							key={index}
							selectTab={selectGame}
							isActive={
								game === (item.node?.frontmatter?.id || '-1')
							}
							game={item.node?.frontmatter || {}}
							theme="PL"
						/>
					))}
				</Flex>
				<Flex
					flex="1"
					direction="column"
					alignItems="center"
					// w="100%"
					pb={calculateHeightValue(150)}
					position="relative"
				>
					<Tabs
						w={calculateWidthValue(1460)}
						mt={calculateHeightValue(30)}
						mb={calculateHeightValue(30)}
						variant="soft-rounded"
						onChange={handleTabClick}
						index={tab}
					>
						<TabList
							w={calculateWidthValue(926)}
							p={calculateWidthValue(6)}
							// h={calculateHeightValue(67)}
						>
							<Tab
								key="player"
								w={calculateWidthValue(437)}
								h={calculateHeightValue(174)}
								mt={calculateHeightValue(14)}
								mb={calculateHeightValue(14)}
								ml={calculateWidthValue(14)}
								fontSize={calculateWidthValue(55)}
								lineHeight={calculateWidthValue(78)}
							>
								Player
							</Tab>
							{groupHasMultipleTeams && (
								<Tab
									key="team"
									w={calculateWidthValue(437)}
									h={calculateHeightValue(174)}
									mt={calculateHeightValue(14)}
									mb={calculateHeightValue(14)}
									ml={calculateWidthValue(14)}
									fontSize={calculateWidthValue(55)}
									lineHeight={calculateWidthValue(78)}
								>
									Team
								</Tab>
							)}

							<Tab
								key="venue"
								w={calculateWidthValue(437)}
								h={calculateHeightValue(174)}
								mt={calculateHeightValue(14)}
								mb={calculateHeightValue(14)}
								ml={calculateWidthValue(14)}
								fontSize={calculateWidthValue(55)}
								lineHeight={calculateWidthValue(78)}
							>
								Venue
							</Tab>
						</TabList>
					</Tabs>
					{tab === venueTabIndex &&
					profile.emailId &&
					leaderboardSummaryData ? (
						<div
							style={{
								maxWidth: '600px',
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								transform: 'scale(1.3)',
								transformOrigin: 'top center'
							}}
						>
							<LeaderboardSummary
								nickname={groupMember?.nickname}
								data={leaderboardSummaryData}
								theme="publicLeaderboard"
							/>
						</div>
					) : (
						(data || []).length > 0 && (
							<FlipMove
								enterAnimation="fade"
								leaveAnimation="fade"
								maintainContainerHeight="true"
							>
								{(data || []).map((item, index) => {
									const key = `${index}-${item?.groupId}-leaderboard-item`
									return (
										<LeaderboardItem
											key={key}
											item={item}
											isTeam={tab === 1}
											gamesPlayed={item.games}
											active={
												item.playerId ===
													(profile?.id || '-') ||
												item.teamId ===
													(team?.id || '-')
											}
											number={index + 1}
											theme="PL"
										/>
									)
								})}
							</FlipMove>
						)
					)}
					{(leaderboardsQuery.isLoading ||
						leaderboardsQuery.hasNextPage) && (
						<Flex ref={sentryRef}>
							<Spinner />
						</Flex>
					)}
				</Flex>

				{userCanPlay && tab !== venueTabIndex && (
					<Drawer
						creditsRemaining={creditsRemaining}
						games={filteredGames}
						isLeaderboardView={true}
					/>
				)}
			</Flex>
			<Modal
				opened={showBonusCreditsNotification}
				onClose={handleCloseBonusCreditsNotification}
				offsetLeft={false}
				title={
					<>
						Want
						<br />
						more?
					</>
				}
				description={
					<>
						<Text variant="m" px="10px" textAlign="center" mb={4}>
							You got it!
						</Text>
						<Text variant="m" px="10px" textAlign="center" mb={4}>
							You've received extra game credits so you can carry
							on playing and improve your previous scores.
						</Text>
						<Text variant="m" px="10px" textAlign="center">
							Credits are automatically reloaded when you complete
							a round of 9 games and we'll continue to reload
							until your session expires.
						</Text>
					</>
				}
				isPink={true}
				primaryBtn={
					<Button
						variant="primary"
						onClick={handleCloseBonusCreditsNotification}
					>
						Let's go
					</Button>
				}
			></Modal>

			<Modal
				opened={openModal}
				onClose={() => setOpenModal(false)}
				offsetLeft={false}
				title={<>ALL-TIME LEADERBOARD</>}
				description={
					<>
						<Text variant="m" px="10px" textAlign="center" mb={4}>
							To unlock your all-time leaderboard, scan the QR
							code on your games card.
						</Text>
						<StaticImage
							src="../images/rfid-card.png"
							style={{
								marginLeft: 'auto',
								marginRight: 'auto',
								width: '50%'
							}}
						/>
					</>
				}
				isPink={true}
				primaryBtn={
					<Button
						variant="primary"
						onClick={() => setOpenModal(false)}
					>
						Close
					</Button>
				}
			></Modal>

			{tab === venueTabIndex && (
				<Flex
					mt={2}
					mb={2}
					mx="auto"
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					px={12}
					py={4}
					style={{
						width: '45%',
						// maxWidth: '420px',
						minHeight: '12vw',
						position: 'fixed',
						bottom: '6vw',
						left: '20vw'
					}}
				>
					<StaticImage
						style={{
							width: '20vw',
							// height: 94,
							position: 'absolute',
							right: 'calc(100%)',
							bottom: '-7.5vw'
						}}
						alt="Duck Bottom"
						src="../images/duck-left.png"
					/>

					<Text
						variant="xs"
						color="white"
						fontSize={'2.2vw'}
						lineHeight={1.2}
					>
						Scroll through each game to view your high scores,
						against the best from your group, today and all time
					</Text>
					<StaticImage
						style={{
							position: 'absolute',
							bottom: 0,
							left: 0,
							right: 0,
							width: '100%',
							height: '100%'
						}}
						src="../images/note-tv-bottom.png"
					/>
				</Flex>
			)}
		</Flex>
	)
}

export default PublicLeaderboardPage
